import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

const Sprite = styled.div`
  ${({ width, height }) => css`
    position: absolute;
    width: ${width}px;
    height: ${height}px;
    background-image: url("/rotor_sprite.jpg");
    background-size: auto 100%;
    font-size: 0;
    overflow: hidden;
    background-size: ${69 * width}px ${height}px;
  `}
`
const TOTAL_FRAMES = 69 - 1
const MAX_WIDTH = 675

const RotorAnimation = ({ rotorImageRef }) => {
  const [currentFrame, setCurrentFrame] = useState(0)
  const [frameWidth, setFrameWidth] = useState(MAX_WIDTH)

  const frameHeight = Math.round(frameWidth * (442 / 675))

  const spriteRef = React.useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window
      const sprite = spriteRef.current

      // get component offset from top
      const { top, bottom } = sprite.getBoundingClientRect()

      const elementHeight = bottom - top
      const elementPosition = bottom - elementHeight

      // get view height
      const vh = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0,
      )

      const start = 1
      const end = TOTAL_FRAMES

      let frame = start

      const offset = vh * 0.2

      if (elementPosition > vh - offset) {
        frame = start
      } else if (elementPosition < 0 - elementHeight - offset) {
        frame = end
      } else {
        const elementOffset = elementPosition + scrollY - vh + offset

        frame = start + ((scrollY - elementOffset) / vh) * (end - start)
      }

      if (frame < start) frame = start
      if (frame > end) frame = end

      setCurrentFrame(Math.round(frame))
    }

    const handleResize = () => {
      const placeholderWidth = rotorImageRef?.current?.imageRef?.current?.offsetWidth || MAX_WIDTH
      setFrameWidth(Math.min(placeholderWidth, MAX_WIDTH))
      handleScroll()
    }

    handleResize()
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [rotorImageRef, frameWidth])

  return (
    <Sprite
      ref={spriteRef}
      width={frameWidth}
      height={frameHeight}
      style={{
        backgroundPositionX: `-${currentFrame * frameWidth}px`,
      }}
    />
  )
}

export default RotorAnimation
